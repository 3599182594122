/**
 * Author: ian
 * Date: 20/02/15
 * Time: 18:04
 */
jQuery(function ($) {

    // set up delay function
    var waitFor = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    $(document).on('click', 'a.show-information', function (e) {

        e.preventDefault();
        e.stopPropagation();

        var $this = $(this),
            isMobile = $(window).width() < 640,
            $container = $this.parent(),
            $information = $container.find('.information'),
            $content = $information.find('.content'),
            pos = $this.hasClass('left') ? $container.outerWidth(true) : $container.outerWidth(true) * -1;

        if ($this.hasClass('open') && typeof e.originalEvent !== 'undefined') {

            $information.fadeOut('fast');
            $this.removeClass('open');
            $this.css({
                'z-index': 'auto'
            });

        } else {


            if (isMobile) {

                $information.css({
                    left: '5%',
                    top: 0,
                    width: '90%'
                });
                $this.css({
                    'z-index': 15
                });

            } else {

                // scroll to position
                $('html,body').animate({
                    scrollTop: $('#team').position().top
                }, {
                    duration: 500,
                    easing: 'swing'
                });

                $information.css({
                    left: pos,
                    width: $container.outerWidth(true)
                });

                $content.css({
                    height: $container.height()
                });

            }

            $content.hide();
            $information.fadeIn('fast', function () {

                $content.fadeIn('fast');
                $this.addClass('open');

            });

        }


    });

    (function () {

        var animating = false;

        function closeNav($nav, $this) {
            animating = true;

            $nav.fadeOut('fast', function () {
                animating = false;
            });

            $this.fadeIn('fast');
        }

        $('#toggle-touch-navigation').on('click', function (e) {

            var $this = $(this),
                $nav = $('#touch-footer-navigation');

            e.preventDefault();

            if (!animating && !$nav.is(':visible')) {

                animating = true;

                $this.fadeOut('fast');

                $nav.fadeIn('fast', function () {


                    animating = false;

                });


                $nav.find('.close').one('click', function (e) {

                    e.preventDefault();
                    closeNav($nav, $this);


                });

                $nav.find('a').one('click', function (e) {

                    closeNav($nav, $this);

                });

            }

        });
    })();

    $(document).find('*[data-error]').each(function () {

        var $this = $(this),
            $label = $this.closest('label'),
            message = $this.data('error');

        $label.append($('<span>', {
            html: message
        }).addClass('error-message'));

        $this.on('focus', function (e) {

            $label.find('.error-message').fadeOut();

        });

        $this.on('blur', function (e) {

            if ($this.val().length == 0) {
                $label.find('.error-message').fadeIn();
            }

        });

    });

    (function () {

        var $form = $('#contact-form'),
            $fields = $form.find('input, textarea'),
            $messageBox = $form.find('.alert-box'),
            $button = $form.find('button[type=submit]');

        function addErrorMessage($field, message) {

            $field = $field.closest('label');
            $field.find('span').remove();
            $field.append($('<span>', {
                'html': message,
                'class': 'error-message'
            }));

        }


        $fields.on('focus', function () {

            $(this).closest('label').find('.error-message').fadeOut('fast');

        });

        $fields.on('blur', function () {

            var $this = $(this);

            if ($this.val().length === 0) {
                $this.closest('label').find('.error-message').fadeIn('fast');
            }

        });

        $(document).on('submit', '#contact-form', function (e) {

            var $this = $(this),
                data = {},
                hasError = false,
                $name = $this.find('#contact-form-name'),
                $email = $this.find('#contact-form-email'),
                $message = $this.find('#contact-form-message');

            e.preventDefault();

            if ($name.val().length === 0) {
                addErrorMessage($name, 'Please enter your name');
                hasError = true;
            }

            if ($email.val().length === 0) {
                addErrorMessage($email, 'Please enter your email address');
                hasError = true;
            }

            if ($message.val().length === 0) {
                addErrorMessage($message, 'Please enter your message');
                hasError = true;
            }

            if (!hasError) {

                data = {
                    name: $name.val(),
                    email: $email.val(),
                    message: $message.val()
                };

                // send form data to API
                $.ajax({
                    url: '/api/contact',
                    type: 'POST',
                    data: JSON.stringify(data),
                    beforeSend: function () {
                        $messageBox.slideUp('fast');
                        $button.html('Sending...');
                        $button.attr('disabled', true);
                    },
                    complete: function () {
                        $button.html('Send');
                        $button.attr('disabled', false);
                    },
                    success: function (result) {

                        if (result.status === 'OK') {

                            $name.val('');
                            $email.val('');
                            $message.val('');

                            $messageBox.html('Your message has been sent').addClass('success').slideDown('fast');

                        }

                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                        $messageBox.html('Your message was NOT sent').addClass('warning').slideDown('fast');

                    }
                });

            }

        });

    })();

    if ($().slick) {

        $('.slider')
            .slick({
                slidesToShow: 1,
                autoplay: false,
                dots: true,
                infinite: true,
                centerMode: true,
                centerPadding: '10%',
                speed: 300,
                swipe: true,
                adaptiveHeight: true,
                prevArrow: '<div class="slick-arrow slick-prev"><i class="fa fa-chevron-left fa-3x" aria-hidden="true"></i></div>',
                nextArrow: '<div class="slick-arrow slick-next"><i class="fa fa-chevron-right fa-3x" aria-hidden="true"></i></div>'
            });

    }

});
